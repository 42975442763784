import React from 'react';
import * as ui from 'semantic-ui-react'
import './App.css';

type IStyloResponse = {
    judgements: {
        confidence: number,
        statement: string
    }[]
}

const APIKey = "af885465-df18-4673-80ae-3deb8eb8897f";

function sendReqStyloAPI(text: string, statements: string[]): Promise<IStyloResponse> {
    const hostname = "api-v1.styloml.com";
    const url = `https://${hostname}/v1/statements/validate?api_key=${APIKey}`;
    const headers = {'Accept': 'application/json', 'Content-Type': 'application/json'};
    const body = JSON.stringify({statements, text});
    return fetch(url, {method: 'POST', headers, body}).then(x => x.json());
}


type IDropDowns = {
    name: string
    options: { text: string, statement: string, priority: number }[]
}[]

const dropDowns: IDropDowns = [
    {
        name: "Competitor",
        options: [
            {priority: 1, text: "SalesForce", statement: "uses SalesForce"},
            {priority: 1, text: "SAP S4 HANA Finance", statement: "uses SAP S4 HANA Finance"},
            {priority: 1, text: "Infor", statement: "uses \"Infor\""},
            {priority: 1, text: "Sage Intacct", statement: "uses Sage Intacct"},
            {priority: 1, text: "FinancialForce", statement: "uses FinancialForce"},
            {priority: 1, text: "Blackbaud", statement: "uses Blackbaud"},
            {priority: 1, text: "Epicor", statement: "uses Epicor"},
            {priority: 1, text: "Intuit QuickBooks", statement: "uses Intuit QuickBooks"},
            {
                priority: 1,
                text: "Microsoft Dynamics 365 Enterprise",
                statement: "uses Microsoft Dynamics 365 Enterprise"
            },
            {priority: 1, text: "Microsoft Nav", statement: "uses Microsoft Nav"},
            {priority: 1, text: "SAP Business by Design", statement: "uses SAP Business by Design"},
            {priority: 1, text: "SAP Business One (B1)", statement: "uses SAP Business One (B1)"},
            {priority: 1, text: "Odoo", statement: "uses Odoo"},
            {priority: 1, text: "Acumatica", statement: "uses Acumatica"},

        ],
    },
    {
        name: "Lead Score",
        options: [
            {priority: 1, text: "A", statement: "definitely buy"},
            {priority: 1, text: "B", statement: "likely buy"},
            {priority: 1, text: "C", statement: "maybe buy"},
            {priority: 1, text: "D", statement: "unlikely buy"},
            {priority: 1, text: "F", statement: "won't buy"},
        ],
    },
    {
        name: "Touch Type",
        options: [
            {priority: 1, text: "Phone Call", statement: "contacted via Phone Call"},
            {priority: 1, text: "Email", statement: "contacted via Email"},
            {priority: 1, text: "TAL Drop", statement: "contacted via TAL Drop"},
            {priority: 1, text: "Pointdrive", statement: "contacted via Pointdrive"},
            {priority: 1, text: "InMail", statement: "contacted via InMail"},
            {priority: 1, text: "Onsite", statement: "contacted via Onsite"},
            {priority: 1, text: "Meeting", statement: "contacted via Meeting"},

        ]
    },
    {
        name: "Contact Person Role",
        options: [
            {priority: 1, text: "Others", statement: "unknown position"},
            {priority: 1, text: "CEO/President/GM", statement: "is ceo or president or general manager"},
            {priority: 1, text: "CFO", statement: "is the cfo"},
            {priority: 1, text: "CIO/IT Executive", statement: "is cio or it executive"},
            {priority: 1, text: "Compliance/Security", statement: "works in compliance or security"},
            {priority: 1, text: "Controller/Finance Executive", statement: "is a Controller or Finance Executive"},
            {priority: 1, text: "COO/Operations Executive", statement: "is the COO or Operations Executive"},
            {priority: 1, text: "Customer Service/Support Manager", statement: "works in customer support"},
            {priority: 1, text: "Ecommerce Developer/Designer", statement: "is an e-commerce Developer Designer"},
            {priority: 1, text: "Ecommerce Executive/Manager", statement: "is a e-commerce Executive or Manager"},
            {priority: 1, text: "Executive (Others)", statement: "is an Executive"},
            {priority: 1, text: "Finance (Others)", statement: "works in finance and isn't an executive"},
            {priority: 1, text: "Finance/Accounting Manager", statement: "is a Finance manager or accounting Manager"},
            {priority: 1, text: "Human Resources", statement: "works in Human Resources"},
            {priority: 1, text: "IT (Others)", statement: "works in information technology"},
            {priority: 1, text: "IT Manager", statement: "is information technology manager"},
            {priority: 1, text: "Manager (Others)", statement: "is Manager"},
            {
                priority: 1,
                text: "Marketing Executive/Brand Manager",
                statement: "is a Marketing Executive or Brand Manager"
            },
            {priority: 1, text: "Procurement", statement: "works in Procurement"},
            {priority: 1, text: "Professional Services Executive", statement: "is a Professional Services Executive"},
            {priority: 1, text: "Professional Services Manager", statement: "is a Professional Services Manager"},
            {priority: 1, text: "Project Manager", statement: "is a Project Manager"},
            {priority: 1, text: "Sales Executive", statement: "is a Sales Executive"},
        ]
    },
    {
        name: "Contact Category",
        options: [
            {priority: 1, text: "Alternative Contact", statement: "is Alternative Contact"},
            {priority: 1, text: "Billing", statement: "works in Billing"},
            {priority: 1, text: "Consultant", statement: "is a Consultant"},
            {priority: 1, text: "Decision Maker", statement: "is a Decision Maker"},
            {priority: 1, text: "Payroll Contact", statement: "works on payroll"},
            {priority: 1, text: "Payroll Contact - Alternative", statement: "assists payroll"},
            {priority: 1, text: "Primary Contact", statement: "primary contact account"},
            {priority: 1, text: "Reference Contact", statement: "ensures customer success"},
        ]
    },
    {
        name: "Win/Loss Reason",
        options: [
            {priority: 9, text: "Pricing", statement: "because expensive"},
            {priority: 10, text: "Features", statement: "because product features"},
            {priority: 1, text: "Duplicate Opportunity", statement: "because duplicate opportunity"},
            {priority: 7, text: "Delayed Decision", statement: "will consider later"},
            {priority: 1, text: "Integration", statement: "because integration important"},
            {priority: 8, text: "Keeping Current System", statement: "because keep current solution"},
            {priority: 7, text: "No Response", statement: "no response"},
            {priority: 1, text: "Partner", statement: "because bought through provider"},
            {priority: 1, text: "Payment Terms / Financing", statement: "because financing"},
            {priority: 1, text: "SaaS Model", statement: "because saas model"},
            {priority: 1, text: "Usability", statement: "because system easy to use"},
        ]
    }
];


const toSingleDropdownOption = (opt: { text: string, statement: string }) => ({
    key: opt.statement,
    statement: opt.statement,
    value: opt.statement,
    text: opt.text
});

class App extends React.Component {
    constructor(props: any) {
        super(props);
        const fv = "Gave Tim a ring, he gave the following feedback regarding Corp Inc. I loved your product but I just don't have the money to buy this right now. Salesforce is cheaper so we're going to stick with it.";
        const state = ({formValue: fv, loading: false} as any);
        for (let i = 0; i < dropDowns.length; i++) {
            state[dropDowns[i].name] = "";
        }

        this.state = state;
    }

    handleSubmit = async () => {
        const state = (this.state as any);

        this.setState({loading: true});


        if (state.formValue.trim().length === 0) {
            console.log("refusing to send empty form");
            return;
        }


        const dropDownFillPromises = dropDowns.map(async (dropDownItem) => {
            const statements = dropDownItem.options.map(x => x.statement);
            const resp = await sendReqStyloAPI(state.formValue, statements);
            const confJudge = dropDownItem.options.map((x, i) => ({
                priority: x.priority,
                statement: x.statement,
                confidence: resp.judgements[i].confidence,
            }));

            console.log(dropDownItem.name, confJudge.map(x => [x.statement, x.confidence]));

            const confJudgeFiltered = confJudge.filter(x => x.confidence > .5);
            if (confJudgeFiltered.length === 0) {
                return;
            }

            const result = confJudgeFiltered.reduce((a, b) => a.priority > b.priority || (a.priority === b.priority && a.confidence > b.confidence) ? a : b);

            this.setState({[dropDownItem.name]: result.statement});
        });
        try {
            await Promise.all(dropDownFillPromises);
        } finally {
            this.setState({loading: false});
        }


        console.log('state', state,);
    };

    render(): React.ReactElement<any, string | React.JSXElementConstructor<any>> | string | number | {} | React.ReactNodeArray | React.ReactPortal | boolean | null | undefined {
        if (!APIKey) {
            return "UNAUTHORIZED";
        }
        const bgColor = {'background-color': '#f4f7f9'};
        const state = (this.state as any);
        return [
            (
                <ui.Menu pointing borderless fitted size={'large'} style={{'background-color': "white"}}>
                    <ui.Menu.Item><img alt={''} src={'/logo.jpg'}/></ui.Menu.Item>
                    <ui.Menu.Item><b>CRM Demo</b></ui.Menu.Item>
                </ui.Menu>
            ),

            (
                <ui.Segment basic={true} style={bgColor}>
                    <ui.Form onSubmit={this.handleSubmit}>
                        <ui.Grid stackable={true}>
                            <ui.GridRow columns={1}>
                                <ui.GridColumn>
                                    <ui.Segment>
                                        <ui.Label size={'big'} attached={'top left'} content={'Sales Rep Notes'}/>
                                        <ui.Grid columns={2}>
                                            <ui.Container>
                                                <ui.FormTextArea
                                                    rows={5}
                                                    onChange={(_, {value}) => this.setState({formValue: value})}
                                                    value={state.formValue}
                                                />
                                                <ui.Button loading={state.loading} type='submit' color={'blue'}>Submit
                                                </ui.Button>
                                            </ui.Container>
                                            <ui.GridRow>
                                                <ui.GridColumn>
                                                </ui.GridColumn>
                                            </ui.GridRow>
                                        </ui.Grid>
                                    </ui.Segment>
                                </ui.GridColumn>
                            </ui.GridRow>
                            <ui.GridRow columns={1}>
                                <ui.GridColumn>
                                    <ui.Segment>
                                        <ui.Label size={'big'} attached={'top left'} content={'Drop Down Fields'}/>
                                        <ui.Grid>
                                            <ui.GridRow columns={3}>
                                                {
                                                    dropDowns.map(x => (
                                                        <ui.GridColumn>
                                                            <ui.Segment>
                                                                <ui.Label attached='top left' children={x.name}/>
                                                                <ui.FormDropdown
                                                                    selection
                                                                    value={state[x.name]}
                                                                    options={x.options.map(toSingleDropdownOption)}
                                                                    onChange={(_, {value}) => (this.setState({[x.name]: value}))}
                                                                />
                                                            </ui.Segment>
                                                        </ui.GridColumn>
                                                    ))
                                                }
                                            </ui.GridRow>
                                        </ui.Grid>
                                    </ui.Segment>
                                </ui.GridColumn>
                            </ui.GridRow>
                        </ui.Grid>
                    </ui.Form>
                </ui.Segment>
            )
        ];
    }
}

export default App;
